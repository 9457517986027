import React, { useState } from 'react'
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import { Flame, ArrowRight, Mail, Terminal, Layout, CheckSquare } from "lucide-react"
import { addSubscriber } from './lib/db'

interface SuccessModalProps {
  onClose: () => void;
}

const SuccessModal = ({ onClose }: SuccessModalProps) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-lg p-6 max-w-md w-full">
      <h3 className="text-lg font-bold mb-4">Thank You!</h3>
      <p className="text-gray-600 mb-4">
        We'll keep you updated on our progress.
      </p>
      <Button onClick={onClose} className="w-full">
        Close
      </Button>
    </div>
  </div>
);

export default function App() {
  const [email, setEmail] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await addSubscriber(email)
      setEmail('')
      setShowSuccessModal(true)
      setTimeout(() => setShowSuccessModal(false), 3000)
    } catch (error) {
      console.error('Error saving email:', error)
      alert('There was an error. Please try again.')
    }
  }

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <header className="px-4 lg:px-6 h-16 flex items-center">
        <div className="flex items-center space-x-2">
          <Flame className="h-6 w-6 text-orange-500" />
          <span className="text-xl font-bold">FireGen AI</span>
        </div>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  Building the Future of AI Productivity
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl">
                  Empowering teams with intelligent agents and tools for seamless AI development and deployment.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 bg-gray-50">
          <div className="container px-4 md:px-6">
            <div className="grid gap-8 md:grid-cols-3">
              <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow md:col-span-1 md:transform md:scale-105">
                <Layout className="h-12 w-12 text-purple-500 mb-4" />
                <h2 className="text-2xl font-bold mb-2">Agents Platform</h2>
                <p className="text-gray-500 mb-4">
                  Your central hub for AI agents management and deployment.
                </p>
                <a href="https://platform.firegen.ai" className="mt-auto">
                  <Button className="w-full">
                    Visit Platform
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </a>
              </div>
              <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                <CheckSquare className="h-12 w-12 text-green-500 mb-4" />
                <h2 className="text-2xl font-bold mb-2">AI Task Manager</h2>
                <p className="text-gray-500 mb-4">
                  Streamline your workflow with AI-powered task management.
                </p>
                <a href="https://app.firegen.ai" className="mt-auto">
                  <Button className="w-full">
                    Open App
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </a>
              </div>
              <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                <Terminal className="h-12 w-12 text-blue-500 mb-4" />
                <h2 className="text-2xl font-bold mb-2">Agents API</h2>
                <p className="text-gray-500 mb-4">
                  Powerful AI endpoints for your agents. Simple integration, powerful results.
                </p>
                <a href="https://api.firegen.ai/docs" className="mt-auto">
                  <Button className="w-full">
                    Explore API
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
                  Get Early Access
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl">
                  Join our journey in building the next generation of AI agents.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <form onSubmit={handleSubmit} className="flex space-x-2">
                  <Input
                    className="max-w-lg flex-1"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Button type="submit">
                    Subscribe
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {showSuccessModal && (
        <SuccessModal onClose={() => setShowSuccessModal(false)} />
      )}
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500">
          © 2024 FireGen AI. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <div className="relative group">
            <a className="text-xs hover:underline underline-offset-4" href="mailto:info@firegen.ai">
              <Mail className="inline-block mr-1 h-3 w-3" />
              Contact
            </a>
            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden group-hover:block bg-black text-white text-xs rounded py-1 px-2 whitespace-nowrap">
              info@firegen.ai
            </div>
          </div>
        </nav>
      </footer>
    </div>
  )
}