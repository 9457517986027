import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';

export const addSubscriber = async (email: string) => {
  try {
    const docRef = await addDoc(collection(db, "subscribers"), {
      email,
      subscribedAt: new Date(),
    });
    return docRef.id;
  } catch (error) {
    console.error("Error adding subscriber: ", error);
    throw error;
  }
}; 